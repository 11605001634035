import React, { SVGProps } from 'react';

export function SuccessIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="120" height="120" rx="60" fill="currentColor" />
      <path d="M76.6666 49.5835L65.436 63.0602C60.9857 68.4005 58.7606 71.0707 55.8333 71.0707C52.9059 71.0707 50.6808 68.4005 46.2305 63.0602L43.3333 59.5835" stroke="black" strokeWidth="4" strokeLinecap="round" />
    </svg>
  );
}
