import React from 'react';
import styled from 'styled-components';

import { SearchIcon } from './SearchIcon';

const SearchInputContainer = styled.div`
  background: ${({ theme }) => theme.color.bgSecondary};
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 100px;
  padding: 12.5px 20px;
`;

const Input = styled.input`
  background: ${({ theme }) => theme.color.bgSecondary};
  border: none;
  border-radius: 0px;
  padding: 0;
  margin-top: 0px;
  height: auto;
  color: ${({ theme }) => theme.color.text};
`;

export function SearchInput(props: {
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  value?: string;
}) {
  const { onChange } = props;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e?.target?.value;
    onChange(val);
  };

  return (
    <SearchInputContainer>
      <SearchIcon />
      <Input value={props?.value} onChange={handleChange} type="text" placeholder="Search" />
    </SearchInputContainer>
  );
}
