import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  color: {
    bg:          'var(--black)',
    text:        'var(--white)',
    hint:        'var(--hint)',
    link:        'var(--primary)',
    btn:         'var(--theme-button-color)',
    btnText:     'var(--theme-button-text-color)',
    bgSecondary: 'var(--theme-secondary-bg-color)',
    success:     '#62c56d',
    warning:     '#FBBC05',
    white:       '#fff',
    black:       '#000',
    redAlert:    'rgb(255,0,0)',
    pink:        '#F03F9F',
    primary:     'var(--primary)'
  },
  gradient: {
    g1: 'linear-gradient(312deg, #82d3ff 0.46%, #ffa1ec 100%)',
    g2: 'linear-gradient(45deg, #fb73e0 0%, #0092e0 100%)',
  },
};
