import React from 'react';
import { useFormContext } from 'react-hook-form';
import OtpInput from 'react-otp-input';

import { ClockSvg } from './icons/ClockSvg';
import * as S from './style';
import { StyledContainer } from '../../components/Layout';

interface IVerifyCodeViewProps {
  loading: boolean
  handleCode: (data: any) => Promise<void>
  seconds: number;
  resendCode: () => Promise<void>
  phone: string;
}

const OTP_LENGTH = 6;

export function VerifyCodeView({
  loading,
  handleCode,
  seconds,
  resendCode,
  phone
}: IVerifyCodeViewProps) {
  const { setValue, watch, handleSubmit } = useFormContext();
  const code: string = watch('code');
  return (
    <StyledContainer>
      <S.ContentWrapper onSubmit={handleSubmit(handleCode)}>
        <header style={{ marginTop: 20 }}>
          <S.TextPrimary children="Enter the code sent to" />
          <S.PhoneText children={phone} />
        </header>
        <S.ActionsContainer>
          {!loading ? (
            <OtpInput
              value={code}
              onChange={(val) => {
                setValue('code', val, { shouldTouch: true, shouldValidate: true });
                handleCode({ code: val }).catch(console.log);
              }}
              numInputs={OTP_LENGTH}
              renderInput={(props) => <S.OtpInputBox required {...props} />}
              inputType="number"
              inputStyle={S.otpInputStyle.inputStyle}
              containerStyle={S.otpInputStyle.containerStyle}
            />
          ) : null}
          <S.ResendCodeBtn
            fill="ghost"
            disabled={loading || !!seconds}
            loading={loading}
            iconLeft={seconds ? <ClockSvg /> : <S.RotateLeftSvgIcon />}
            label={seconds ? `Resend code in ${seconds} sec` : 'Resend code'}
            onClick={resendCode}
          />
        </S.ActionsContainer>
      </S.ContentWrapper>
    </StyledContainer>
  );
}
