import { yupResolver } from '@hookform/resolvers/yup';
import { RecaptchaVerifier } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import * as S from './Login.style';
import { handleCreateAccount } from '../../actions/account';
import { StyledContainer } from '../../components/Layout';
import { openToast } from '../../lib/Toast';
import { PhoneInput } from '../../ui';
import { isPhoneValid } from '../../ui/PhoneInput/PhoneInput';
import { firebaseAuth } from '../../utils/firebase';

const schema = yup.object().shape({
  phone: yup
    .string()
    .required('Please enter a valid phone number')
    .test(
      'is valid phone',
      'Phone number is not valid',
      (value) => isPhoneValid(value)
    )
});

function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [inFlight, setInFlight] = useState(false);

  const form = useForm({
    mode:          'onChange',
    resolver:      yupResolver(schema),
    defaultValues: {
      phone: decodeURIComponent(searchParams.get('phone') ?? ''),
    }
  });

  const {
    handleSubmit,
    formState: { isValid }
  } = form;

  useEffect(() => {
    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'sign-in-phone', {
      size:     'invisible',
      callback: (response) => {
        console.log('captcha ready');
      },
      'expired-callback': () => {
        console.log('captcha expired');
      }
    });
  }, []);

  const submitPhoneCheck = async (
    data: { phone: string }
  ) => {
    setInFlight(true);
    await firebaseAuth.signOut();
    const public_key = searchParams.get('public_key');
    const methodNames = searchParams.get('methodNames');
    const contract_id = searchParams.get('contract_id');
    const encrypt_key = searchParams.get('encrypt_key').replaceAll(' ', '+');;

    searchParams.set('phone', data.phone);

    try {
      await handleCreateAccount({
        accountId: null,
        phone:     data.phone,
      });
      const newSearchParams = new URLSearchParams({
        phone:      data.phone,
        isRecovery: 'true',
        ...(public_key ? { public_key_lak: public_key } : {}),
        ...(contract_id ? { contract_id } : {}),
        ...(methodNames ? { methodNames } : {}),
        ...(encrypt_key ? { encrypt_key } : {})
      });

      navigate(`/verify-code?${newSearchParams.toString()}`);
    } catch (error: any) {
      const errorMessage = typeof error?.message === 'string' ? error.message : 'Something went wrong';

      openToast({
        type:  'ERROR',
        title: errorMessage,
      });
    } finally {
      setInFlight(false);
    }
  };

  return (
    <FormProvider {...form}>
      <StyledContainer>
        <S.LoginFormContainer>
          <S.Header>
            <S.Title children="Please confirm your country code and enter your phone number." />
          </S.Header>
          <S.ActionsContainer>
            <PhoneInput />
            <div>
              {/* тут появляется рекапча  встраиваясь автоматом в разметку */}
              <S.Button
                size="large"
                type="submit"
                label={inFlight ? 'Sending...' : 'Send Code'}
                variant="affirmative"
                data-test-id="login_button"
                onClick={handleSubmit(submitPhoneCheck)}
                disabled={!isValid || inFlight}
                id="sign-in-phone"
              />
            </div>
          </S.ActionsContainer>
        </S.LoginFormContainer>
      </StyledContainer>

    </FormProvider>
  );
}

export default Login;
