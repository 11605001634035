import React, { SVGProps } from 'react';

export function SearchIcon(props:SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1_3875)">
        <circle cx="9.16663" cy="9.6665" r="7.5" stroke="white" strokeWidth="1.8" />
        <path d="M16.25 16.75L19.1667 19.6667" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_1_3875">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
