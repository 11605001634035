import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchAccountIds } from '../../api';
import FirestoreController from '../../lib/firestoreController';
import { decodeIfTruthy } from '../../utils';

export function FetchAccountId() {
  const [searchParams] = useSearchParams();
  const [result, setResult] = useState({});

  useEffect(() => {
    const public_key = decodeIfTruthy(searchParams.get('public_key'));

    if (!window.firestoreController) {
      window.firestoreController = new FirestoreController();
    }

    (async function () {
      const accountId = await fetchAccountIds(public_key);
      setResult({ accountId });
    }());
  }, []);

  return <code>{JSON.stringify(result)}</code>;
}
