import { ConfirmationResult } from '@firebase/auth';

interface IConfirmationProcessParams {
    confirmationResult: ConfirmationResult | null
}
const confirmationProcess = () => {
  let _confirmationResult = null;

  return {
    setParams: ({ confirmationResult }: IConfirmationProcessParams) => {
      if (confirmationResult) {
        _confirmationResult = confirmationResult;
      }
    },
    getParams: () => {
      return { confirmationResult: _confirmationResult };
    }
  };
};

const confirmationProcessProvider = confirmationProcess();

export default confirmationProcessProvider;
