import debug from 'debug';
import React from 'react';
import {
  Route, BrowserRouter as Router, Routes,
} from 'react-router-dom';

// eslint-disable-next-line import/extensions, import/no-unresolved
import 'react-international-phone/style.css';
import AuthCallbackPage from './components/AuthCallback/AuthCallback';
import Devices from './components/Devices/Devices';
import RemoveTrailingSlash from './components/RemoveTrailingSlash/RemoveTrailingSlash';
import FastAuthController from './lib/controller';
import './styles/reset.css';
import './styles/theme.css';
import './styles/globals.css';
import FirestoreController from './lib/firestoreController';
import { Toaster } from './lib/Toast';
import { VerifyCode } from './pages';
import { AppRedirect } from './pages/AppRedirect/AppRedirect';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import { FetchAccountId } from './pages/FetchAccountId/FetchAccountId';
import Login from './pages/Login/Login';
import GlobalStyle from './styles/index';
import { basePath, networkId } from './utils/config';

(window as any).fastAuthController = new FastAuthController({
  accountId: '',
  networkId
});

if (!window.firestoreController) {
  window.firestoreController = new FirestoreController();
}

const faLog = debug('fastAuth');
const log = faLog.extend('App');
const log2 = log.extend('watwat');

export default function App() {
  faLog('init');
  log('faLog');
  log2('faLogzzzzz');

  // @ts-ignore
  return (
    <>
      <Toaster />
      <GlobalStyle />
      <Router basename={basePath || ''}>
        <RemoveTrailingSlash />
        <Routes>
          <Route path="/">
            {/* <Route index element={<AuthIndicator />} /> */}
            <Route path="login" element={<Login />} />
            {/* <Route path="rpc" element={<RpcRoute />} /> */}
            <Route path="create-account" element={<CreateAccount />} />
            <Route path="verify-code" element={<VerifyCode />} />
            <Route path="auth-callback" element={<AuthCallbackPage />} />
            <Route path="app-redirect" element={<AppRedirect />} />
            <Route path="devices" element={<Devices />} />
            <Route path="my-account-id" element={<FetchAccountId />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
