import React, {
  FC,
  ReactElement, useCallback, useEffect, useRef
} from 'react';
import ReactDOM from 'react-dom';

import * as S from './style';

type ModalProps = {
  className?: string
  onClose: () => void
  title?: string | ReactElement
  description?: string
  children: any
  open: boolean
}

const PORTAL_TARGET = 'portal';

const portalElement = document.getElementById(PORTAL_TARGET) as HTMLElement;

export const BottomSlideModal: FC<ModalProps> = (props) => {
  const {
    children, className, onClose, title, description, open
  } = props;

  const outsideRef = useRef(null);

  const handleWrapperClick = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (evt.target === outsideRef.current) {
      onClose();
    }
  };

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (typeof document !== 'undefined' && open) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  if (!open) return null;

  return ReactDOM.createPortal(
    <S.WrapModal ref={outsideRef} onClick={handleWrapperClick}>
      <S.CardWrapper className={className}>
        <div>
          {title && <S.Title children={title} />}
          <S.Close onClick={onClose} children="X" />
        </div>

        {description && <S.Description children={description} />}

        {children}
      </S.CardWrapper>
    </S.WrapModal>,
    portalElement
  );
};
