import React from 'react';
import { useMetaTags } from 'react-metatags-hook';

import * as S from './style';
import redirectParamsProvider from '../../provider/redirectParams';

export function AppRedirect() {
  const {
    pk, accountId, failure, token
  } = redirectParamsProvider.getParams();

  console.log({ pk, accountId, token });

  useMetaTags({
    metas: [
      { 'http-equiv': 'refresh', content: `0; URL=openmoney://auth/${pk}@%OM%@${accountId}@%OM%@${token}` },
    ],
  }, []);

  return (
    <S.ContentWrapper className="fullscreen_notification">
      {!failure ? <S.SuccessSvgIcon /> : <S.FailureSvgIcon />}
      <S.Text>
        {failure ? 'Failure' : 'Success!'}
      </S.Text>
    </S.ContentWrapper>
  );
}
