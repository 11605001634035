import styled from 'styled-components';

import { SuccessIcon } from './icon';
import { FailureIcon } from './icon/FailureIcon';

export const SuccessSvgIcon = styled(SuccessIcon)`
  color: ${({ theme }) => theme.color.primary};
`;

export const FailureSvgIcon = styled(FailureIcon)`
  color: ${({ theme }) => theme.color.redAlert};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.color.text};
  font-size: 24px;
  font-weight: 500;
`;

export const ContentWrapper = styled.div`
  &.fullscreen_notification {
    background-color: ${({ theme }) => theme.color.bg};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    gap: 25px;
  }
`;
