import { PhoneNumberUtil } from 'google-libphonenumber';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CountryIso2, FlagImage, defaultCountries, parseCountry, usePhoneInput
} from 'react-international-phone';

import * as S from './PhoneInput.style';
import { SearchInput } from '../../pages/Login/components/SearchInput';
import { BottomSlideModal } from '../BottomSlideModal';

interface IPhoneInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> { }

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const PhoneInput: FC<IPhoneInputProps> = (props) => {
  const { setValue, control } = useFormContext();

  const {
    inputValue,
    handlePhoneValueChange,
    inputRef,
    country,
    setCountry,
  } = usePhoneInput({
    value:     props?.value as string,
    countries: defaultCountries,
    onChange:  (data) => setValue('phone', data.phone, { shouldValidate: true, shouldTouch: true }),
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [filterdDefaultCountries, setFilterdDefaultCountries] = useState(defaultCountries);

  const handleSelectCountry = (iso2: CountryIso2) => () => {
    setCountry(iso2);
    setFilterdDefaultCountries(defaultCountries);
    setModalOpen(false);
  };

  const handleFilterCounties = (val: string) => {
    const filtered = defaultCountries.filter((el) => parseCountry(el).dialCode.includes(val.replace('+', ''))
      || parseCountry(el).name.toLowerCase().includes(val.toLowerCase()));
    setFilterdDefaultCountries(filtered);
  };

  const { invalid, isTouched } = control.getFieldState('phone');
  useEffect(() => {
    if (!invalid && isTouched) {
      inputRef.current.blur();
    }
  }, [invalid, isTouched, inputRef]);

  return (
    <>
      <S.InputContainer>
        <FlagImage iso2={country?.iso2} onClick={() => setModalOpen(true)} />
        <S.Separator />
        <Controller
          name="phone"
          control={control}
          render={({ field: { onBlur, onChange, ref } }) => (
            <S.Input
              type="tel"
              ref={(input) => { inputRef.current = input; ref(input); }}
              value={inputValue}
              onChange={(e) => {
                onChange(e);
                handlePhoneValueChange(e);
              }}
              placeholder="Phone number"
              onBlur={onBlur}
            />
          )}
        />
      </S.InputContainer>

      <BottomSlideModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Country code"
        children={(
          <>
            <SearchInput onChange={handleFilterCounties} />
            <S.ModalContentContainer>
              {filterdDefaultCountries.map((el) => (
                <S.CountryCard key={parseCountry(el).iso2} onClick={handleSelectCountry(parseCountry(el).iso2)}>
                  <S.FlagBtnWrapper>
                    <FlagImage iso2={parseCountry(el).iso2} />
                  </S.FlagBtnWrapper>
                  <S.CountryInfoWrapper>
                    <S.CountryName children={parseCountry(el).name} />
                    <S.CountryCode children={`(+${parseCountry(el).dialCode})`} />
                  </S.CountryInfoWrapper>
                </S.CountryCard>
              ))}
            </S.ModalContentContainer>
          </>
        )}
      />
    </>
  );
};
