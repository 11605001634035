export interface IAppRedirect {
  accountId: string
  pk: string
  failure: boolean
  token: string
}

const redirectParams = () => {
  let _accountId = '';
  let _pk = '';
  let _token = '';
  let _failure = false;

  return {
    setParams: ({
      accountId, pk, token, failure
    }: IAppRedirect) => {
      if (accountId) {
        _accountId = accountId;
      }
      if (pk) {
        _pk = pk;
      }
      if (failure) {
        _failure = failure;
      }
      if (token) {
        _token = token;
      }
    },
    getParams: () => {
      return {
        accountId: _accountId, pk: _pk, failure: _failure, token: _token
      };
    }
  };
};

const redirectParamsProvider = redirectParams();

export default redirectParamsProvider;
