import styled from 'styled-components';

import { Button as UIBUtton } from '../../lib/Button/Button';

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 350px;
  width: 100%;
`;

export const InputContainer = styled.div`
  padding: 17.5px 20px;
  border-radius: 100px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.bgSecondary};
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 120px;
`;

export const Separator = styled.div`
  background: ${({ theme }) => theme.color.hint};
  width: 1px;
  height: 30px;
`;
export const Input = styled.input`
  background-color: transparent !important;
  color: ${({ theme }) => theme.color.text};
  border: none !important;
  margin-top: 0;
  height: 30px;
  padding: 0px;
`;

export const Button = styled(UIBUtton)`
  width: 100%;
  height: 65px;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  gap: 20px;
`;

export const FlagBtnWrapper = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.bgSecondary};
  width: fit-content;
  padding: 10px;
`;

export const CountryCard = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const CountryInfoWrapper = styled.div`
  display: flex;
  gap: 5px;
`;
export const CountryName = styled.span`
  color: ${({ theme }) => theme.color.text};
`;
export const CountryCode = styled.span`
  color: ${({ theme }) => theme.color.hint};
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.color.text};
  width: 335px;
`;

export const Header = styled.header`
  min-height: 72px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;
  width: 100%;
`;
