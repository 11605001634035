import { signInWithPhoneNumber } from 'firebase/auth';

import confirmationProcessProvider from '../provider/confirmationProcess';
import { firebaseAuth } from '../utils/firebase';

export const handleCreateAccount = async ({
  accountId, phone
}) => {
  try {
    // @ts-ignore
    const confirmationResult = await signInWithPhoneNumber(firebaseAuth, phone, window.recaptchaVerifier);
    confirmationProcessProvider.setParams({ confirmationResult });
  } catch (e) {
    console.log(e);
    throw new Error('Something went wrong, please retry later');
  }

  window.localStorage.setItem('phoneForSignIn', phone);
  return {
    accountId,
  };
};
