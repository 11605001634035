import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { VerifyCodeView } from './VerifyCode.view';
import { useCountdown } from '../../hooks/useCountdown';
import { openToast } from '../../lib/Toast';

const OTP_LENGTH = 6;

const schema = yup.object().shape({
  code: yup
    .string()
    .length(OTP_LENGTH)
    .required('Please enter a valid code')
});

const TIMER_TIMEOUT = 60;

export function VerifyCode() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [timer, _, setIsPlaying] = useCountdown(TIMER_TIMEOUT);

  const [query] = useSearchParams();
  const phone = query.get('phone');
  const phoneInternationalFormat = useRef(parsePhoneNumber(phone).formatInternational());

  useEffect(() => {
    setIsPlaying(true);
  }, []);

  useEffect(() => {
    window.localStorage.setItem('phoneForSignIn', phone);
  }, [phone]);

  const [currentSearchParams] = useSearchParams();
  const form = useForm({
    mode:          'all',
    resolver:      yupResolver(schema),
    defaultValues: {
      code: decodeURIComponent(currentSearchParams.get('code') ?? ''),
    }
  });
  const { setValue } = form;

  const handleCode = async (data: { code: string }) => {
    const { code } = data;
    if (code.length < OTP_LENGTH) {
      return;
    }
    setLoading(true);
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });

    try {
      const accountRequiredButNotThere = !query.get('accountId') && query.get('isRecovery') !== 'true';
      if (
        accountRequiredButNotThere
        || !query.get('phone')
        || !query.get('phone').length
      ) return;

      const accountId = query.get('accountId');
      const isRecovery = query.get('isRecovery');
      const public_key_lak = query.get('public_key_lak');
      const contract_id = query.get('contract_id');
      const methodNames = query.get('methodNames');
      const encrypt_key = query.get('encrypt_key').replaceAll(' ', '+');;

      const searchParams = new URLSearchParams({
        ...(accountId ? { accountId } : {}),
        ...(isRecovery ? { isRecovery } : {}),
        ...(public_key_lak ? { public_key_lak } : {}),
        ...(contract_id ? { contract_id } : {}),
        ...(methodNames ? { methodNames } : {}),
        ...(phone ? { phone } : {}),
        ...(code ? { code } : {}),
        ...(encrypt_key ? { encrypt_key } : {})
      });

      window.localStorage.setItem('phoneForSignIn', phone);
      navigate(`/auth-callback?${searchParams.toString()}`);

      openToast({
        type:  'SUCCESS',
        title: 'Code entered',
      });
    } catch (error) {
      openToast({
        type:  'ERROR',
        title: 'Error',
      });
    } finally {
      setValue('code', '');
      setLoading(false);
    }
  };

  const resendCode = async () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  return (
    <FormProvider {...form}>
      <VerifyCodeView
        loading={loading}
        seconds={timer}
        phone={phoneInternationalFormat.current}
        handleCode={handleCode}
        resendCode={resendCode}
      />
    </FormProvider>
  );
}
