import React, { SVGProps } from 'react';

export function ClockSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.5 6.6665L10.5 9.58317C10.5 10.2735 11.0596 10.8332 11.75 10.8332V10.8332H13" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M18.8334 9.99984C18.8334 14.6022 15.1025 18.3332 10.5001 18.3332C5.89771 18.3332 2.16675 14.6022 2.16675 9.99984C2.16675 5.39746 5.89771 1.6665 10.5001 1.6665C15.1025 1.6665 18.8334 5.39746 18.8334 9.99984Z" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" />
    </svg>
  );
}
