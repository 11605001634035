import React, { SVGProps } from 'react';

function CrossSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M13.0306 11.9694C13.1715 12.1103 13.2507 12.3014 13.2507 12.5006C13.2507 12.6999 13.1715 12.891 13.0306 13.0319C12.8897 13.1728 12.6986 13.2519 12.4994 13.2519C12.3001 13.2519 12.109 13.1728 11.9681 13.0319L8 9.06249L4.03063 13.0306C3.88973 13.1715 3.69864 13.2507 3.49938 13.2507C3.30012 13.2507 3.10902 13.1715 2.96813 13.0306C2.82723 12.8897 2.74808 12.6986 2.74808 12.4994C2.74808 12.3001 2.82723 12.109 2.96813 11.9681L6.9375 7.99999L2.96938 4.03061C2.82848 3.88972 2.74933 3.69862 2.74933 3.49936C2.74933 3.30011 2.82848 3.10901 2.96938 2.96811C3.11027 2.82722 3.30137 2.74806 3.50063 2.74806C3.69989 2.74806 3.89098 2.82722 4.03188 2.96811L8 6.93749L11.9694 2.96749C12.1103 2.82659 12.3014 2.74744 12.5006 2.74744C12.6999 2.74744 12.891 2.82659 13.0319 2.96749C13.1728 3.10838 13.2519 3.29948 13.2519 3.49874C13.2519 3.69799 13.1728 3.88909 13.0319 4.02999L9.0625 7.99999L13.0306 11.9694Z" fill="#706F6C" />
    </svg>

  );
}

export default CrossSvg;
