import { yupResolver } from '@hookform/resolvers/yup';
import { RecaptchaVerifier } from 'firebase/auth';
import * as React from 'react';
import {
  useCallback, useEffect, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import * as S from './CreateAccount.style';
import { handleCreateAccount } from '../../actions/account';
import { StyledContainer } from '../../components/Layout';
import { openToast } from '../../lib/Toast';
import { getHashByString } from '../../service/crypto/getPWDHashByString';
import { getRandomString } from '../../service/crypto/utils/randomString';
import { PhoneInput } from '../../ui';
import { isPhoneValid } from '../../ui/PhoneInput/PhoneInput';
import { network } from '../../utils/config';
import { firebaseAuth } from '../../utils/firebase';
import {
  accountAddressPatternNoSubAccount
} from '../../utils/form-validation';

const checkIsAccountAvailable = async (desiredUsername: string): Promise<boolean> => {
  try {
    const response = await fetch(network.nodeUrl, {
      method:  'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id:      'dontcare',
        method:  'query',
        params:  {
          request_type: 'view_account',
          finality:     'final',
          account_id:   `${desiredUsername}.${network.fastAuth.accountIdSuffix}`,
        },
      }),
    });
    const data = await response.json();
    if (data?.error?.cause?.name === 'UNKNOWN_ACCOUNT') {
      return true;
    }

    if (data?.result?.code_hash) {
      return false;
    }

    return false;
  } catch (error: any) {
    openToast({
      title: error.message,
      type:  'ERROR'
    });
    return false;
  }
};

let setValueRef = null;

const setUsername = (username: string) => setValueRef?.('username', username, { shouldValidate: true });

const schema = yup.object().shape({
  phone: yup
    .string()
    .test(
      'is valid phone',
      'Phone number is not valid',
      (value) => isPhoneValid(value)
    )
    .required('Please enter a valid phone number'),
  username: yup
    .string()
    .required('Please enter a valid account ID')
    .matches(
      accountAddressPatternNoSubAccount,
      'Accounts must be lowercase and may contain - or _, but they may not begin or end with a special character or have two consecutive special characters.'
    )
    .test(
      'is-account-available',
      async (username, context) => {
        if (username) {
          const isAvailable = await checkIsAccountAvailable(username);

          if (!isAvailable) {
            const anotherUsername = getHashByString(username, getRandomString(6));
            console.log({ anotherUsername })
            setUsername(anotherUsername);
          } else {
            return true;
          }
        }

        return false;
      }
    )
});

function CreateAccount() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const encrypt_key = searchParams.get('encrypt_key').replaceAll(' ', '+');;

  const [inFlight, setInFlight] = useState(false);

  const form = useForm({
    mode:           'all',
    resolver:       yupResolver(schema),
    defaultValues:  {
      phone:    '',
      username: '',
    }
  });

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: {
      errors, isValid, touchedFields
    },
  } = form;

  setValueRef = setValue;

  const formsPhone = watch('phone');

  const createAccount = useCallback(async (data: { phone: string; username: string; }) => {
    setInFlight(true);
    const public_key =  searchParams.get('public_key');
    const methodNames = searchParams.get('methodNames');
    const contract_id = searchParams.get('contract_id');

    console.log({ finalUsername: data.username });

    try {
      const fullAccountId = `${data.username}.${network.fastAuth.accountIdSuffix}`;
      const {
        accountId
      } = await handleCreateAccount({
        accountId: fullAccountId,
        phone:     data.phone,
      });
      const newSearchParams = new URLSearchParams({
        accountId,
        phone:      data.phone,
        isRecovery: 'false',
        ...(public_key ? { public_key_lak: public_key } : {}),
        ...(contract_id ? { contract_id } : {}),
        ...(methodNames ? { methodNames } : {}),
        ...(encrypt_key ? { encrypt_key } : {})
      });
      navigate(`/verify-code?${newSearchParams.toString()}`);
    } catch (error: any) {
      openToast({
        type:  'ERROR',
        title: error.message,
      });
    } finally {
      setInFlight(false);
    }
  }, [searchParams]);

  useEffect(() => {
    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'sign-up-phone', {
      size:     'invisible',
      callback: (response) => {
        console.log('captcha ready');
      },
      'expired-callback': () => {
        console.log('captcha expired');
      }
    });
  }, []);

  useEffect(() => {
    const phone = searchParams.get('phone');
    const username = searchParams.get('accountId');

    if (phone) {
      reset({
        phone,
        username: getHashByString(phone),
      });
      trigger();

      if (username) {
        handleSubmit(createAccount)();
      }
    }
  }, [createAccount, handleSubmit, reset, searchParams, trigger]);

  useEffect(() => {
    if (!errors?.phone?.message && touchedFields?.phone) {
      setValue('username', getHashByString(formsPhone), { shouldValidate: true, shouldDirty: true });
    }
    // Should only trigger when email changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsPhone, setValue, errors?.phone?.message, touchedFields?.phone]);

  return (
    <FormProvider {...form}>
      <StyledContainer>
        <S.CreateAccountForm>
          <header>
            <S.Title children="Create account" />
            <p className="desc">
              <span>Have an account?</span>
              {' '}
              <S.Link to={`/login?encrypt_key=${encrypt_key}`} data-test-id="create_login_link">Sign in</S.Link>
            </p>
          </header>
          <S.ActionsContainer>
            <PhoneInput />
            <div style={{ width: '100%' }}>
              {/* recaptcha location */}
              <S.Button
                disabled={!isValid || inFlight}
                label={inFlight ? 'Sending...' : 'Continue'}
                onClick={handleSubmit(createAccount)}
                variant="affirmative"
                type="submit"
                size="large"
                data-test-id="continue_button_create"
                id="sign-up-phone"
              />
            </div>
          </S.ActionsContainer>
        </S.CreateAccountForm>
      </StyledContainer>
    </FormProvider>
  );
}

export default CreateAccount;
