import styled from 'styled-components';

import { RotateLeftSvg } from './icons';
import { Button } from '../../lib/Button';

export const TextPrimary = styled.span`
  color: ${({ theme }) => theme.color.text};
`;
export const PhoneText = styled.p`
  color: ${({ theme }) => theme.color.text};
  font-weight: 600;
`;
export const OtpInputBox = styled.input`
  outline: none;
  background: ${({ theme }) => theme.color.hint};
  border: 2px solid ${({ theme }) => theme.color.bg};
  border-radius: 15px;
  height: 55px;
  font-size: 24px;
  color: ${({ theme }) => theme.color.text};
  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.primary};
  }
`;

export const ResendCodeBtn = styled(Button)<{ disabled: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.color.hint : theme.color.primary)};
  font-weight: 600;
  margin: 0 auto;
  width: 100%;
  border: none !important;
`;

export const otpInputStyle = {
  inputStyle:     { width: '45px', padding: 0 },
  containerStyle: { justifyContent: 'center', gap: 10 },
};

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ActionsContainer = styled.div`
  flex: 1;
  align-content: center;
`;

export const RotateLeftSvgIcon = styled(RotateLeftSvg)`
  color: ${({ theme }) => theme.color.primary};
`;
