import { isPassKeyAvailable, createKey } from '@near-js/biometric-ed25519';
import { sleep } from '@orion-js/helpers';
import { captureException } from '@sentry/react';
import BN from 'bn.js';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { createNEARAccount, fetchAccountIds } from '../../api';
import { setAccountIdToController } from '../../lib/controller';
import FirestoreController from '../../lib/firestoreController';
import confirmationProcessProvider from '../../provider/confirmationProcess';
import redirectParamsProvider from '../../provider/redirectParams';
import { encryptWithKey } from '../../service/crypto/encryptWithKey';
import {
  decodeIfTruthy
} from '../../utils';
import { networkId } from '../../utils/config';
import { checkFirestoreReady } from '../../utils/firebase';
import {
  getAddKeyAction, getAddLAKAction
} from '../../utils/mpc-service';
import {KeyPair} from "near-api-js";

const StyledStatusMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.color.bg};
`;

const onCreateAccount = async ({
  oidcKeypair,
  accessToken,
  accountId,
  publicKeyFak,
  public_key_lak,
  contract_id,
  methodNames,
  setStatusMessage,
  gateway,
  navigate,
  secretKey,
  encrypt_key,
}) => {
  console.log({ publicKeyFak });
  const res = await createNEARAccount({
    accountId,
    fullAccessKeys:    publicKeyFak ? [publicKeyFak] : [],
    limitedAccessKeys: public_key_lak ? [{
      public_key:   public_key_lak,
      receiver_id:  contract_id,
      allowance:    '250000000000000',
      method_names: methodNames ?? '',
    }] : [],
    accessToken,
    oidcKeypair,
  });

  if (res.type === 'err') return;

  // Add device
  await window.firestoreController.addDeviceCollection({
    fakPublicKey: publicKeyFak,
    lakPublicKey: public_key_lak,
    gateway,
    accountId
  });

  setStatusMessage('Account created successfully!');

  setStatusMessage('Redirecting to app...');

  const recoveryPK = await window.fastAuthController.getUserCredential(accessToken);
  await window.firestoreController.addAccountIdPublicKey(recoveryPK, accountId);

  redirectParamsProvider.setParams({
    accountId, pk: encryptWithKey(encrypt_key, secretKey), token: encryptWithKey(encrypt_key, accessToken), failure: false
  });

  const allAccessKeysAfter = await window.fastAuthController.fetchAllAccessKeys();
  console.log('allAccessKeysAfter: ', allAccessKeysAfter);

  navigate('/app-redirect');
};

export const onSignIn = async ({
  accessToken,
  publicKeyFak,
  public_key_lak,
  contract_id,
  methodNames,
  setStatusMessage,
  searchParams,
  navigate,
  gateway,
  secretKey,
  recoveryPK,
  accountId,
  encrypt_key,
}) => {
  // Stop from LAK with multi-chain contract
  console.log({ publicKeyFak });

  // TODO: If we want to remove old LAK automatically, use below code and add deleteKeyActions to signAndSendActionsWithRecoveryKey
  // const existingDevice = await window.firestoreController.getDeviceCollection(publicKeyFak);
  // // delete old lak key attached to webAuthN public Key
  // const deleteKeyActions = existingDevice
  //   ? getDeleteKeysAction(existingDevice.publicKeys.filter((key) => key !== publicKeyFak)) : [];

  // onlyAddLak will be true if current browser already has a FAK with passkey
  const onlyAddLak = !publicKeyFak || publicKeyFak === 'null';
  const addKeyActions = onlyAddLak
    ? getAddLAKAction({
      publicKeyLak: public_key_lak,
      contractId:   contract_id,
      methodNames,
      allowance:    new BN('250000000000000'),
    }) : getAddKeyAction({
      publicKeyLak:      public_key_lak,
      webAuthNPublicKey: publicKeyFak,
      contractId:        contract_id,
      methodNames,
      allowance:         new BN('250000000000000'),
    });

  const allAccessKeysBefore = await window.fastAuthController.fetchAllAccessKeys();
  console.log('allAccessKeysBefore: ', allAccessKeysBefore);

  return (window as any).fastAuthController.signAndSendActionsWithRecoveryKey({
    oidcToken: accessToken,
    accountId,
    recoveryPK,
    actions:   addKeyActions
  })
    .then((res) => res.json())
    .then(async (res) => {
      const failure = res['Receipts Outcome']
        .find(({ outcome: { status } }) => Object.keys(status).some((k) => k === 'Failure'))?.outcome?.status?.Failure;
      if (failure?.ActionError?.kind?.LackBalanceForState) {
        searchParams.set('publicKeyFak', publicKeyFak);

        navigate(`/devices?${searchParams.toString()}`);
      } else {
        await checkFirestoreReady();
        await window.firestoreController.addDeviceCollection({
          fakPublicKey: onlyAddLak ? null : publicKeyFak,
          lakPublicKey: public_key_lak,
          gateway,
          accountId,
        });

        setStatusMessage('Account recovered successfully!');

        setStatusMessage('Redirecting to app...');

        window.fastAuthController.setAccountId(accountId);

        // // @ts-ignore
        // for (const key in allAccessKeys.keys.filter((item) => item.public_key !== publicKeyFak)) {
        //   try {
        //     // @ts-ignore
        //     const deleteAccessKeys = await window.firestoreController.deleteKeyAsDelegatedAction(allAccessKeys.keys[key].public_key, accessToken);
        //     // @ts-ignore
        //     console.log('delete access key res: ', deleteAccessKeys, allAccessKeys.keys[key].public_key);
        //   } catch (e) {
        //     console.log('delete access key err: ', e);
        //   }
        // }
        //
        // const allAccessKeysPlus = await window.fastAuthController.fetchAllAccessKeys();
        // console.log('allAccessKeysPlus: ', allAccessKeysPlus);

        await sleep(5000);

        const allAccessKeysAfter = await window.fastAuthController.fetchAllAccessKeys();
        console.log('allAccessKeysAfter: ', allAccessKeysAfter);

        // @ts-ignore
        redirectParamsProvider.setParams({
          accountId, pk: encryptWithKey(encrypt_key, secretKey), token: encryptWithKey(encrypt_key, accessToken), failure: false
        });
        navigate('/app-redirect');
      }
    });
};

function AuthCallbackPage() {
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState('Loading...');
  const [searchParams] = useSearchParams();

  const handleFailure = () => {
    redirectParamsProvider.setParams({
      accountId: '', pk: '', token: '', failure: true
    });
    navigate('/app-redirect');
  };

  // The signIn should run only once
  useEffect(() => {
    const signInProcess = async () => {
      const code = decodeIfTruthy(searchParams.get('code'));

      if (code.length === 6) {
        let accountId = decodeIfTruthy(searchParams.get('accountId'));
        const isRecovery = decodeIfTruthy(searchParams.get('isRecovery'));
        const public_key_lak = decodeIfTruthy(searchParams.get('public_key_lak'));
        const contract_id = decodeIfTruthy(searchParams.get('contract_id'));
        const methodNames = decodeIfTruthy(searchParams.get('methodNames'));
        const encrypt_key = searchParams.get('encrypt_key').replaceAll(' ', '+');

        console.log({ EK: encrypt_key });

        if (!window.firestoreController) {
          window.firestoreController = new FirestoreController();
        }

        setStatusMessage('Verifying phone...');

        try {
          const { confirmationResult } = confirmationProcessProvider.getParams();
          const { user } = await confirmationResult.confirm(code);
          if (!user) {
            return handleFailure();
          }

          const accessToken = await user.getIdToken();

          setStatusMessage(isRecovery ? 'Recovering account...' : 'Creating account...');
          setAccountIdToController({ accountId, networkId });

          await window.fastAuthController.claimOidcToken(accessToken);

          let publicKeyFak: string;
          let secretKey: string;

          let recoveryPK = '';
          if (!accountId) {
            recoveryPK = await window.fastAuthController.getUserCredential(accessToken);
            const accountIds = await fetchAccountIds(recoveryPK);
            if (!accountIds.length) {
              return handleFailure();
            }
            accountId = accountIds[0];
          }

          console.log({ accountId });

          if (!window.fastAuthController.getAccountId()) {
            window.fastAuthController.setAccountId(accountId);
          }

          let keyPair = null;
          if (await isPassKeyAvailable()) {
            const phone = window.localStorage.getItem('phoneForSignIn');
            console.log('key username: ', phone);

            if (isRecovery) {
              keyPair = await window.fastAuthController.getKeyPair(phone);
            } else {
              keyPair = await createKey(phone);
            }

            publicKeyFak = keyPair?.keyPair?.getPublicKey().toString() || keyPair.getPublicKey().toString();
            secretKey = keyPair?.keyPair?.toString() || keyPair.toString();

            if (keyPair.restored && isRecovery) {
              redirectParamsProvider.setParams({
                accountId, pk: encryptWithKey(encrypt_key, secretKey), token: encryptWithKey(encrypt_key, accessToken), failure: false
              });
              return navigate('/app-redirect');
            }
          } else {
            keyPair = KeyPair.fromRandom('ed25519');

            publicKeyFak = keyPair.getPublicKey().toString();
            secretKey = keyPair.toString();

            setStatusMessage("Your device doesn't support passkeys.");
            await sleep(3000);
          }

          await window.fastAuthController.setKey(keyPair);

          const oidcKeypair = await window.fastAuthController.getKey(`oidc_keypair_${accessToken}`);
          if (!window.firestoreController) {
            window.firestoreController = new FirestoreController();
          }
          await window.firestoreController.updateUser({
            userUid:   user.uid,
            oidcToken: accessToken,
          });

          const callback = isRecovery ? onSignIn : onCreateAccount;
          await callback({
            oidcKeypair,
            accessToken,
            recoveryPK,
            accountId,
            publicKeyFak,
            public_key_lak,
            contract_id,
            methodNames,
            setStatusMessage,
            navigate,
            searchParams,
            gateway: 'openmoney-mobile',
            secretKey,
            encrypt_key
          });
        } catch (e) {
          captureException(e);
          console.log('error:', e);
          handleFailure();
        }
      } else {
        handleFailure();
      }
    };

    signInProcess().catch((e) => { console.log(e); });
  }, [navigate, searchParams]);

  return <StyledStatusMessage data-test-id="callback-status-message">{statusMessage}</StyledStatusMessage>;
}

export default AuthCallbackPage;
