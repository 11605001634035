import React from 'react';
import styled from 'styled-components';

type StyledContainerProps = {
  inIframe?: boolean;
  children: JSX.Element
}

export const ContainerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.bg};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  padding-bottom: 60px;
  padding-top: 20px;

  header {
    text-align: center;
  }
`;

function StyledContainer({ inIframe, children }: StyledContainerProps) {
  return inIframe ? children : <ContainerWrapper>{children}</ContainerWrapper>;
}

export default StyledContainer;
