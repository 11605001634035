import { Link as UILink } from 'react-router-dom';
import styled from 'styled-components';

import { Button as UIButton } from '../../lib/Button';

export const CreateAccountForm = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.color.text}
`;

export const Button = styled(UIButton)`
  width: 100%;
  min-height: 65px;
`;
export const Link = styled(UILink)`
  color: ${({ theme }) => theme.color.primary}
`;

export const ActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
